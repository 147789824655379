<template>
  <div class="shared-related">
    <el-row
      class="title"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <h3 class="m-0">
        DANH SÁCH LIÊN KẾT CỦA {{ data.title.toUpperCase() }}: {{ detail.name.toUpperCase() }}
      </h3>
      <shared-actions
        :data="detail"
        :type="data.type"
        :actions="data.index.actions"
        :table-data="[]"
        :router-data="data"
        @showQR="() => qrVisible = true"
        @reloadPage="() => $router.push({ name: data.name })"
      />
    </el-row>
    <el-row class="shared-tabs">
      <el-tabs v-model="activeType">
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab.value"
          :label="tab.name.toUpperCase()"
          :name="tab.value"
        />
      </el-tabs>
    </el-row>
    <div v-if="detail.id">
      <shared-index
        :key="currentRouter.type"
        :type="currentRouter.type"
        :titles="currentRouter.index.titles"
        :columns="currentRouter.index.columns"
        :actions="currentRouter.index.actions"
        :header-actions="currentRouter.index.headerActions.filter((a) => a !== 'filter')"
        :filterable="true"
        :detail="{ ...detail, type: data.type }"
        :tab="currentRouter"
        :is-related="true"
      />
    </div>
    <qrcode-popup
      :visible="qrVisible"
      :row="detail"
      @close="qrVisible = false"
    />
  </div>
</template>

<script>
import { getAccountRouters } from '@/utils/filters'
import SharedIndex from './index'
import SharedActions from './components/Actions.vue'
import QrcodePopup from '../popups/Qrcode.vue'

export default {
  name: 'SharedDetail',
  components: {
    SharedIndex,
    SharedActions,
    QrcodePopup
  },
  props: {
    data: Object,
    detail: Object,
    tabs: Array
  },
  data() {
    return {
      qrVisible: false,
      activeType: this.tabs[0].value
    }
  },
  computed: {
    currentTabType() {
      return this.tabs.find((r) => r.value === this.activeType)
    },
    currentRouter() {
      return getAccountRouters().find((r) => r.type === this.activeType)
    }
  }
}
</script>

<style lang="scss" scoped>
.shared-related {
  .title {
    padding: 15px;
    background-color: #630104;
    color: white;
  }
  .shared-tabs {
    padding: 0 15px 15px 15px;
    background-color: #630104;
    color: white;
  }
}
</style>
<style lang="scss">
.shared-related {
  .shared-tabs {
    .el-tabs__item {
      color: white;
      &:hover {
        color: #409eff;
      }
      &.is-active {
        color: #409eff;
      }
    }
  }
}
</style>
